

























































































import { TemplateTokenConfig } from "@/lib/configuration/template-token.configuration";
import { TokenCategoriesEnum } from "@/lib/enum/templateEnums/tokenCategories.enum";
import { ITemplateContext } from "@/lib/interfaces/template/templateContext.interface";
import { PartnerTemplateModule } from "@/store/modules/partner-template.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  MrfiktivAdminTemplateViewModelGen,
  MrfiktivPartnerTemplateViewModelGen
} from "../../services/mrfiktiv/v1/data-contracts";
import { AdminTemplateModule } from "../../store/modules/admin-template.store";
import Card from "../utility/Card.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import TableWrapper, { ITableWrapperHeader } from "../utility/TableWrapper.vue";
import Tooltip from "../utility/tooltip.vue";
import { getFlagEmojiByLanguage } from "@/lib/CountryCodeHelper";

@Component({
  components: { ConfirmActionDialog, Card, TableWrapper, Tooltip },
  filters: {
    getFlagEmojiByLanguage
  }
})
export default class TemplateSelectionDialog extends Vue {
  @Prop()
  partnerId?: string;

  @Prop()
  context!: ITemplateContext;

  isDialogActive = false;

  selectedItems: MrfiktivPartnerTemplateViewModelGen[] | MrfiktivAdminTemplateViewModelGen[] = [];

  loading = false;

  get headers(): ITableWrapperHeader[] {
    const headers: ITableWrapperHeader[] = [
      { text: String(this.$t("components.template.dialog.selection.tempalteTitle")), value: "meta.title" },
      { text: String(this.$t("language")), value: "meta.language" },
      {
        text: String(this.$t("components.template.dialog.selection.description")),
        value: "meta.description",
        sortable: false
      },
      {
        text: String(this.$t("components.template.dialog.selection.categories")),
        value: "meta.categories",
        sortable: false
      },
      {
        text: String(this.$t("components.template.dialog.selection.context")),
        value: "context",
        sortable: false
      }
    ];

    if (this.partnerId) {
      headers.push({
        text: String(this.$t("components.template.dialog.selection.isAdminTemplate")),
        value: "isAdminTemplate",
        align: "center",
        width: "30px",
        sortable: false
      });
    }

    headers.push({
      text: "",
      value: "timestamp.created",
      type: "date",
      width: "40px",
      align: "end"
    });

    return headers;
  }

  get templates(): MrfiktivPartnerTemplateViewModelGen[] | MrfiktivAdminTemplateViewModelGen[] {
    let templates: MrfiktivPartnerTemplateViewModelGen[] | MrfiktivAdminTemplateViewModelGen[] = [];
    if (this.partnerId) {
      templates = PartnerTemplateModule.filteredAndSorted;
    } else {
      templates = AdminTemplateModule.filteredAndSorted;
    }

    if (!templates) {
      return [];
    }

    return templates;
  }

  icon(item: any) {
    return item.isAdminTemplate ? "mdi-check" : "mdi-close";
  }

  getDescription(item: MrfiktivPartnerTemplateViewModelGen | MrfiktivAdminTemplateViewModelGen) {
    const text = item.meta.description || item.content.subject || item.content.body || "";
    if (text.length > 200) {
      return text.substring(0, 200) + "...";
    }

    return text;
  }

  async mounted() {
    try {
      this.loading = true;
      if (this.partnerId) {
        await PartnerTemplateModule.getAllTemplates(this.partnerId);
      } else {
        await AdminTemplateModule.getAllTemplates();
      }
    } catch (e) {
      this.$log.error(e);
      this.$toast.error((e as any).message);
    } finally {
      this.loading = false;
    }
  }

  getTemplateContext(template: MrfiktivPartnerTemplateViewModelGen | MrfiktivAdminTemplateViewModelGen) {
    const available: string[] = [];
    const missing: string[] = [];
    const tokens = template.content?.tokens;

    if (!tokens) {
      return { available, missing };
    }

    const tokenCategories = tokens
      .map(token => token.split(".")[0])
      .filter((cat: string) => TemplateTokenConfig.objectCategories.includes(cat as TokenCategoriesEnum));

    tokenCategories.forEach(category => {
      if (!this.context[category]) {
        missing.push(category);
      } else {
        available.push(category);
      }
    });

    return { available: [...new Set(available)], missing: [...new Set(missing)] };
  }

  select(template: MrfiktivPartnerTemplateViewModelGen | MrfiktivAdminTemplateViewModelGen) {
    this.$emit("select", template);
  }

  goToTemplates() {
    if (this.partnerId) {
      return this.$router.push({
        name: "TemplateListPartnerView",
        params: { partnerId: this.partnerId }
      });
    }

    return this.$router.push({
      name: "TemplateListView"
    });
  }
}
