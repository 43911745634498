











































import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { isMobile } from "@/lib/utility/isMobile";
import { MrfiktivCustomFieldViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { CustomFieldEnum, CustomFieldModule } from "@/store/modules/custom-field.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import { IAction } from "./MHeader.vue";
import { StringKeys } from "@/lib/utility/typescriptTypes";
import { transformBooleanToText } from "@/lib/utility/boolean-helper";

/**
 * What type of field is passed in. Can be property or custom field
 */
export enum DetailTableTypeEnum {
  CUSTOM = "customField",
  PROPERTY = "property"
}

/**
 * Defines the display type.
 */
export enum DetailTableVisualizationEnum {
  /**
   * Display value as text
   */
  TEXT = "text",

  /**
   * Display value as chip
   */
  CHIP = "chip",

  /**
   * Displays value as double digit date
   */
  DATE = "date",

  /**
   * DIY Slot
   */
  SLOT = "slot"
}

/**
 * Config to define what should be displayed in the table
 */
export interface IMDetailTableConfig<T = any> {
  /**
   * key, name of property or id of custom field
   */
  key: StringKeys<T>;

  /**
   * type, could be prop or customfield
   */
  type: DetailTableTypeEnum;

  /**
   * Type of viz
   */
  visualization?: DetailTableVisualizationEnum;

  /**
   * Displayvalues can be different from the real values, useful for enums etc.
   */
  displayConfig?: { value: string | number | boolean; displayValue: string; color?: string }[];
}

interface ITableDataRow {
  key: string;
  text: string;
  value: string | number | boolean;
  visualization: DetailTableVisualizationEnum;
  color?: string;
}

@Component({})
export default class MDetailTable<T> extends Vue {
  /**
   * Title of the table
   */
  @Prop()
  title?: string;

  /**
   * Description of the table
   */
  @Prop()
  description?: string;

  /**
   * Title of the table
   */
  @Prop({ default: false })
  outlined?: boolean;

  /**
   * Adds overflow y on mobile devices
   */
  @Prop({ default: true })
  scrollableOnMobile?: string;

  /**
   * Item which data should be displayed
   */
  @Prop()
  item!: T;

  /**
   * Custom fields of the item
   */
  @Prop()
  customFieldValues!: { id: string; value: number | boolean | string }[];

  /**
   * Config of the table
   */
  @Prop()
  config!: IMDetailTableConfig[];

  /**
   * Only relevant for type "property"
   * Root-path in the i18n file for the property translations. E.G.
   *
   * {
   *  id: "maksldm",
   *  type: "car",
   *  registration: {
   *    name: "Max"
   *    ...
   *  }
   * }
   * If you want to display registraion name, the config should includes:
   *
   * {
   *  type: "property"
   *  key: "registration.name"
   * }
   *
   * i18nPath is the base in the i18 File. E.g "vehicles.details"
   * The translation is searched in i18 file at posision vehicles.details.registration.name
   *
   */
  @Prop({ default: "" })
  i18nPath!: string;

  /**
   * Actionconfiguration under the table
   */
  @Prop()
  showMoreAction!: IAction;

  /**
   * Copy on click
   */
  @Prop({ default: true })
  copyClipboard!: boolean;

  DetailtableVisualizationEnum = DetailTableVisualizationEnum;

  get mobileClass() {
    if (this.scrollableOnMobile && isMobile()) {
      return "max-height: 85vh; overflow-y: auto";
    }
    return "";
  }

  get tableStyle() {
    if (this.copyClipboard) {
      return "cursor: pointer";
    }
    return "";
  }

  /**
   * returns the table data that should be displayed
   */
  get tableData(): ITableDataRow[] {
    const data = [];
    for (const configuration of this.config) {
      let value = this.getPropertyByPath(this.item, configuration.key) || "";
      let color = undefined;

      if (configuration.type === DetailTableTypeEnum.PROPERTY) {
        if (configuration.displayConfig) {
          const found = configuration.displayConfig.find(m => m.value === value);
          if (found) {
            value = found.displayValue || "";
            color = found?.color || undefined;
          }
        }

        data.push({
          key: configuration.key,
          text: this.$t(this.i18nPath + "." + configuration.key).toString(),
          value: value || "",
          visualization: configuration?.visualization || DetailTableVisualizationEnum.TEXT,
          color: color
        });
      } else if (configuration.type === DetailTableTypeEnum.CUSTOM) {
        let value = this.getCustomFieldValue(configuration.key);
        const customFieldConfig = this.getCustomFieldConfig(configuration.key);

        let visualization = DetailTableVisualizationEnum.TEXT;
        let color = undefined;

        if (customFieldConfig?.type === CustomFieldEnum.DATE) {
          visualization = DetailTableVisualizationEnum.DATE;
        } else if (
          [CustomFieldEnum.SINGLE_SELECT, CustomFieldEnum.MULTI_SELECT].includes(
            customFieldConfig?.type as CustomFieldEnum
          )
        ) {
          visualization = DetailTableVisualizationEnum.CHIP;
          if (value) {
            color = this.getCustomFieldSingleSelectColor(customFieldConfig, value);
          }
        }

        if (customFieldConfig?.type === CustomFieldEnum.BOOLEAN) {
          value = transformBooleanToText(String(value));
        }

        data.push({
          key: configuration.key,
          text: customFieldConfig?.label || "",
          value: value || "",
          visualization: visualization,
          color: color
        });
      }
    }
    return data;
  }

  /**
   * Gets chip color
   * @param item
   */
  getChipColor(item: ITableDataRow) {
    return item?.color;
  }

  copyToClipBoard(item: ITableDataRow) {
    if (!this.copyClipboard) {
      return;
    }
    let value = "";
    if (item.visualization === DetailTableVisualizationEnum.DATE) {
      value = this.simpleDoubleDigitDate(item.value);
    } else {
      value = item.value.toString();
    }
    navigator.clipboard.writeText(value);
    this.$toast.info(this.$t("components.partner.PartnerReportInitializeCard.linkCopied"));
  }

  /**
   * Get custom field config
   * @param id
   */
  getCustomFieldConfig(id: string) {
    return CustomFieldModule.paginationList.find(c => c.id === id);
  }

  /**
   * Get color of the custom field single selct field
   * @param c
   * @param value
   */
  getCustomFieldSingleSelectColor(c?: MrfiktivCustomFieldViewModelGen, value?: string | number | boolean) {
    const found = c?.configuration?.values.find(c => c.value === value);
    return found?.color;
  }

  /**
   * get custom field value
   * @param key
   */
  getCustomFieldValue(key: string) {
    if (!this.customFieldValues) {
      return "";
    }
    return this.customFieldValues.find(c => c.id === key)?.value;
  }

  /**
   *
   * @param date
   */
  simpleDoubleDigitDate(date: string | number | boolean | undefined) {
    if (!date) {
      return "";
    }
    if (typeof date !== "string") {
      return "";
    }
    return simpleDoubleDigitDate(date, this.$t("locale").toString());
  }

  /**
   * returns property by path
   * @param obj
   * @param path
   */
  getPropertyByPath<T>(obj: T, path: string): string | number | boolean | undefined {
    const properties: string[] = path.split(".");

    return properties.reduce((acc: any, currentProperty: string) => {
      if (acc && typeof acc === "object" && currentProperty in acc) {
        return acc[currentProperty];
      } else {
        return undefined;
      }
    }, obj);
  }

  /**
   *
   * @param action emits action if action is clicked
   */
  actionClicked(action: IAction | undefined) {
    if (action) {
      this.$emit("actionClicked", action);
    }
  }
}
