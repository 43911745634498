


































import { Component } from "vue-property-decorator";
import CustomFieldText from "./CustomFieldText.vue";
import CustomFieldValueChip from "./CustomFieldValueChip.vue";
import Debug from "../utility/Debug.vue";

@Component({
  components: { CustomFieldValueChip, Debug }
})
export default class CustomFieldSingleSelect extends CustomFieldText {
  get values() {
    return this.customField.configuration?.values ?? [];
  }

  get input(): string {
    if (typeof this.value === "string") {
      return this.value;
    }

    return this.value[0];
  }

  set input(v: string) {
    this.$emit("input", v);
  }
}
