








































import { Component } from "vue-property-decorator";
import Debug from "../utility/Debug.vue";
import CustomFieldText from "./CustomFieldText.vue";
import CustomFieldValueChip from "./CustomFieldValueChip.vue";

@Component({
  components: { CustomFieldValueChip, Debug }
})
export default class CustomFieldMultiSelect extends CustomFieldText {
  get values() {
    return this.customField.configuration?.values ?? [];
  }

  get input(): string[] {
    if (typeof this.value === "string") {
      return [this.value];
    }

    return this.value;
  }

  set input(v: string[]) {
    this.$emit("input", v);
  }
}
