































































import { CustomFieldCreateDtoHelper, CustomFieldEnum } from "@/store/modules/custom-field.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import CustomFieldText from "./CustomFieldText.vue";
import CustomFieldNumber from "./CustomFieldNumber.vue";
import CustomFieldBoolean from "./CustomFieldBoolean.vue";
import CustomFieldDate from "./CustomFieldDate.vue";
import CustomFieldDateTime from "./CustomFieldDateTime.vue";
import CustomFieldSingleSelect from "./CustomFieldSingleSelect.vue";
import CustomFieldMultiSelect from "./CustomFieldMultiSelect.vue";

@Component({
  components: {
    CustomFieldText,
    CustomFieldNumber,
    CustomFieldBoolean,
    CustomFieldDate,
    CustomFieldDateTime,
    CustomFieldSingleSelect,
    CustomFieldMultiSelect
  }
})
export default class CustomField extends Vue {
  @Prop()
  customField!: CustomFieldCreateDtoHelper;

  @Prop()
  value!: string;

  @Prop()
  required!: boolean;

  @Prop()
  disabled!: boolean;

  @Prop()
  loading?: boolean;

  get CustomFieldEnum() {
    return CustomFieldEnum;
  }

  get input() {
    return this.value;
  }

  set input(v: string) {
    this.$emit("input", v);
  }
}
