import { render, staticRenderFns } from "./CustomFieldMultiSelect.vue?vue&type=template&id=8357e1ca&"
import script from "./CustomFieldMultiSelect.vue?vue&type=script&lang=ts&"
export * from "./CustomFieldMultiSelect.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VListItemContent,VListItemSubtitle,VListItemTitle,VSelect,VSheet})
