





































































import FilterSelection from "@/components/filter/FilterSelection.vue";
import { requiredRule } from "@/lib/rules/requiredRule";
import { PageFilterTypes } from "@/lib/utility/data/page-filter-types.enum";
import { PaginationFilterListElement } from "@/store/modules/base-pagination.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import CustomFieldValueChip from "../report/CustomFieldValueChip.vue";
import RefsCostGroup from "../utility/RefsCostGroup.vue";
import RefsProject from "../utility/RefsProject.vue";
import RefsUser from "../utility/RefsUser.vue";
import RefsVehicle from "../utility/RefsVehicle.vue";
import RefsHandover from "../utility/RefsHandover.vue";
import RefsSnapshot from "../utility/RefsSnapshot.vue";
// import RefsTicket from "../utility/RefsTicket.vue";

@Component({
  components: {
    FilterSelection,
    RefsCostGroup,
    RefsUser,
    RefsVehicle,
    RefsProject,
    RefsHandover,
    RefsSnapshot,
    // RefsTicket,
    CustomFieldValueChip
  }
})
export default class FilterCardPaginationValueField extends Vue {
  @Prop()
  value!: string;

  @Prop()
  filterConfiguration!: PaginationFilterListElement;

  getFilterConfigurationItems(filterConfiguration: PaginationFilterListElement) {
    const itemCallback = filterConfiguration?.config?.itemCallback;
    if (itemCallback) {
      return itemCallback();
    }

    return filterConfiguration?.config?.items;
  }

  get required() {
    return [requiredRule()];
  }
  get booleanRules() {
    return [(v: boolean) => v === true || v === false || "Must be defined"];
  }
  get objectIdRules() {
    return [requiredRule(), (v: string) => v.length === 24 || "ObjectIds must be 24 characters long"];
  }

  /**
   * Get the configuration of the input/selection field based on the given filter
   */
  get filterSelectionConfiguration() {
    const filterConfiguration = this.filterConfiguration;

    if (!filterConfiguration) {
      return undefined;
    }

    /**
     * The type of the input field
     */
    const typeMap = new Map([
      [PageFilterTypes.STRING, undefined],
      [PageFilterTypes.NUMBER, "number"],
      [PageFilterTypes.OBJECT_ID, undefined],
      [PageFilterTypes.BOOLEAN, undefined],
      [PageFilterTypes.ENUM, undefined],
      [PageFilterTypes.DATE, "date"],
      [PageFilterTypes.CUSTOM_FIELD_DATE_VALUE, "date"],
      [PageFilterTypes.CUSTOM_FIELD_NUMBER_VALUE, "number"],
      [PageFilterTypes.CUSTOM_FIELD_VALUE, undefined]
    ]);

    /**
     * The validation of the input
     */
    const rulesMap = new Map([
      [PageFilterTypes.STRING, this.required],
      [PageFilterTypes.NUMBER, this.required],
      [PageFilterTypes.OBJECT_ID, this.objectIdRules],
      [PageFilterTypes.BOOLEAN, this.booleanRules],
      [PageFilterTypes.ENUM, this.required],
      [PageFilterTypes.DATE, this.required]
    ]);

    /**
     * The placeholder of the input
     */
    const placeholderMap = new Map([
      [PageFilterTypes.STRING, "Value"],
      [PageFilterTypes.NUMBER, "Value"],
      [PageFilterTypes.OBJECT_ID, "61e9880bb4c41b6269ef5649"],
      [PageFilterTypes.BOOLEAN, this.$t("yes") + "/" + this.$t("no")],
      [PageFilterTypes.ENUM, this.getFirstItemFromConfigItems(filterConfiguration)],
      [PageFilterTypes.DATE, "2000-12-31"]
    ]);

    /**
     * The configuration of the input
     */
    return {
      key: filterConfiguration.key,
      type: typeMap.get(filterConfiguration.type),
      items:
        filterConfiguration?.type === PageFilterTypes.BOOLEAN
          ? [true, false]
          : this.getFilterConfigurationItems(this.filterConfiguration),
      component: filterConfiguration.config?.component,
      rules: rulesMap.get(filterConfiguration.type),
      placeholder: placeholderMap.get(filterConfiguration.type),
      itemValue: filterConfiguration.config?.itemValue,
      mapItemToComponent: filterConfiguration.config?.mapItemToComponent
    };
  }

  readonly FilterTypes = PageFilterTypes;

  get input(): string {
    return this.value;
  }
  set input(v: string) {
    this.$emit("input", v);
  }

  private getFirstItemFromConfigItems(filterConfiguration: PaginationFilterListElement) {
    const items = this.getFilterConfigurationItems(filterConfiguration);
    if (!items?.length) {
      return "";
    }

    if (typeof items[0] === "string") {
      return items[0];
    } else {
      return "";
    }
  }
}
