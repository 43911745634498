






























import { MrfiktivCustomFieldListElementViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { Component, Prop, Vue } from "vue-property-decorator";
import CustomField from "./CustomField.vue";
import CustomFieldDetailIcon from "./CustomFieldDetailIcon.vue";
import Debug from "../utility/Debug.vue";
import { CustomFieldEnum } from "@/store/modules/custom-field.store";
import { ICustomFieldValue } from "@/models/custom-field-value.entity";

/**
 * Component to fill out values for custom form
 *
 * PROPS:
 * v-model/value: list of input values
 * customFieldConfig: List of custom fields to show and edit
 * valid.sync: form validation
 *
 * EVENT:
 * change: Changes to input
 */
@Component({
  components: { CustomFieldDetailIcon, CustomField, Debug }
})
export default class CustomFieldListForm extends Vue {
  @Prop()
  private value!: ICustomFieldValue[];

  @Prop()
  customFieldConfig!: MrfiktivCustomFieldListElementViewModelGen[];

  @Prop()
  private valid!: boolean;

  @Prop({ default: false })
  disabled!: boolean;

  @Prop({ default: false })
  loading!: boolean;

  /**
   * Map of the values of the custom field
   */
  customFieldValuesMap: Map<string, ICustomFieldValue> = new Map();

  /**
   * Map of custom field config where key is the id of the field
   */
  customFieldConfigMap: Map<string, MrfiktivCustomFieldListElementViewModelGen> = new Map();

  /**
   * The map is not update in the create ticket dialog if a new project is selected without this
   */
  mapUpdateKey = 0;

  get isValid() {
    return this.valid;
  }

  set isValid(v: boolean) {
    this.$emit("update:valid", v);
  }

  debug(config: MrfiktivCustomFieldListElementViewModelGen) {
    return `Id: ${config.customField.id} Key: ${config.customField.key}
Value: ${this.getValueForCustomFieldId(config.customField.id)?.value}
Required: ${config.configuration.isRequired}`;
  }

  mounted() {
    // sync values, customFieldConfig
    for (const config of this.customFieldConfig) {
      this.customFieldConfigMap = new Map(this.customFieldConfig.map(obj => [obj.customField.id, obj]));

      let value = this.value.find(f => f.id === config.customField.id) as ICustomFieldValue;

      if (!value && config.customField.id) {
        value = { id: config.customField.id, value: "" };
      }

      this.customFieldValuesMap.set(config.customField.id, value);
      this.mapUpdateKey++;
    }
  }

  getValueForCustomFieldId(id: string) {
    return this.customFieldValuesMap.get(id);
  }

  customFieldValueUpdate(value: string, customFieldConfigId: string) {
    this.$log.debug(value, customFieldConfigId);

    const customFieldValue = this.customFieldValuesMap.get(customFieldConfigId);
    if (customFieldValue) {
      customFieldValue.value = value;

      // Add current timezone for `DATE_TIME` fields
      if (this.customFieldConfigMap.get(customFieldConfigId)?.customField.type === CustomFieldEnum.DATE_TIME) {
        customFieldValue.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      }

      this.$log.debug(customFieldValue);

      this.$emit("input", Array.from(this.customFieldValuesMap.values()));
      this.$emit("change", customFieldValue);
    }
  }
}
