/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MrfiktivAdminTemplateControllerDeleteParamsGen,
  MrfiktivAdminTemplateControllerGetByKeyParamsGen,
  MrfiktivAdminTemplateControllerRenderParamsGen,
  MrfiktivAdminTemplateControllerUpdateParamsGen,
  MrfiktivAdminTemplateViewModelGen,
  MrfiktivCreateAdminTemplateDtoGen,
  MrfiktivRenderedTemplateViewModelGen,
  MrfiktivRenderTemplateDtoGen,
  MrfiktivUpdateAdminTemplateDtoGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class AdminTemplate<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags admin-template
   * @name AdminTemplateControllerGetAll
   * @summary (Admin) Get all admin templates
   * @request GET:/template
   * @secure
   * @response `200` `(MrfiktivAdminTemplateViewModelGen)[]` The found ressources
   */
  adminTemplateControllerGetAll = (params: RequestParams = {}) =>
    this.http.request<MrfiktivAdminTemplateViewModelGen[], any>({
      path: `/template`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags admin-template
   * @name AdminTemplateControllerCreate
   * @summary (Admin) Post an admin template
   * @request POST:/template
   * @secure
   * @response `201` `MrfiktivAdminTemplateViewModelGen` The created resource
   */
  adminTemplateControllerCreate = (data: MrfiktivCreateAdminTemplateDtoGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivAdminTemplateViewModelGen, any>({
      path: `/template`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags admin-template
   * @name AdminTemplateControllerGetByKey
   * @summary (Admin) Get an admin template by key
   * @request GET:/template/{key}
   * @secure
   * @response `200` `MrfiktivAdminTemplateViewModelGen` The found resource
   */
  adminTemplateControllerGetByKey = (
    { key, ...query }: MrfiktivAdminTemplateControllerGetByKeyParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivAdminTemplateViewModelGen, any>({
      path: `/template/${key}`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags admin-template
   * @name AdminTemplateControllerUpdate
   * @summary (Admin) Update a admin template
   * @request PATCH:/template/{key}
   * @secure
   * @response `200` `MrfiktivAdminTemplateViewModelGen` The updated resource
   */
  adminTemplateControllerUpdate = (
    { key, ...query }: MrfiktivAdminTemplateControllerUpdateParamsGen,
    data: MrfiktivUpdateAdminTemplateDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivAdminTemplateViewModelGen, any>({
      path: `/template/${key}`,
      method: "PATCH",
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags admin-template
   * @name AdminTemplateControllerDelete
   * @summary (Admin) Delete an admin template by key
   * @request DELETE:/template/{key}
   * @secure
   * @response `200` `MrfiktivAdminTemplateViewModelGen` The deleted resource
   */
  adminTemplateControllerDelete = (
    { key, ...query }: MrfiktivAdminTemplateControllerDeleteParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivAdminTemplateViewModelGen, any>({
      path: `/template/${key}`,
      method: "DELETE",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags admin-template
   * @name AdminTemplateControllerRender
   * @summary (Admin) Render an admin template
   * @request POST:/template/{key}/render
   * @secure
   * @response `201` `MrfiktivRenderedTemplateViewModelGen` The rendered template
   */
  adminTemplateControllerRender = (
    { key, ...query }: MrfiktivAdminTemplateControllerRenderParamsGen,
    data: MrfiktivRenderTemplateDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivRenderedTemplateViewModelGen, any>({
      path: `/template/${key}/render`,
      method: "POST",
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
}
