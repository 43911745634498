import { FilterTypes } from "@/lib/filterable";
import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { ILocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.interface";
import {
  MrfiktivAdminTemplateViewModelGen,
  MrfiktivCreateAdminTemplateDtoGen,
  MrfiktivRenderedTemplateViewModelGen,
  MrfiktivRenderTemplateDtoGen,
  MrfiktivUpdateAdminTemplateDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import adminTemplateService from "@/services/shared/adminTemplateService";
import {
  ThgAdminTemplateViewModelGen,
  ThgCreateAdminTemplateDtoGen,
  ThgRenderedTemplateViewModelGen,
  ThgRenderTemplateDtoGen,
  ThgUpdateAdminTemplateDtoGen
} from "@/services/thg/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, getModule, Module, Mutation } from "vuex-module-decorators";
import { BaseStore } from "../base.store";
import { PaginationFilterListElement } from "./base-pagination.store";
import { LanguageCodeEnum } from "@/lib/enum/language-code.enum";

export const AdminTemplateDataAccessLayer = new (class extends AbstractLocalDataAccessLayer<
  MrfiktivAdminTemplateViewModelGen
> {
  getIdentifier(entity: MrfiktivAdminTemplateViewModelGen): string {
    return `${entity.key}.${entity.meta.language}`;
  }
})();

@Module({
  dynamic: true,
  namespaced: true,
  name: "admin-template",
  store
})
export class AdminTemplateStore extends BaseStore<MrfiktivAdminTemplateViewModelGen> {
  protected _data: ILocalDataAccessLayer<MrfiktivAdminTemplateViewModelGen, string> = AdminTemplateDataAccessLayer;

  filterOptions: PaginationFilterListElement[] = [
    new PaginationFilterListElement({
      key: "isAdminTemplate",
      type: FilterTypes.BOOLEAN,
      displayName: "components.template.dialog.selection.isAdminTemplate"
    }),
    new PaginationFilterListElement({
      key: "content.subject",
      type: FilterTypes.STRING,
      displayName: "Name"
    }),
    new PaginationFilterListElement({
      key: "key",
      type: FilterTypes.STRING,
      displayName: "Key"
    })
  ];

  private _rendered: MrfiktivRenderedTemplateViewModelGen | ThgRenderedTemplateViewModelGen | undefined = undefined;
  private _adminTemplate: MrfiktivAdminTemplateViewModelGen | ThgAdminTemplateViewModelGen | undefined = undefined;
  private _adminTemplates: MrfiktivAdminTemplateViewModelGen[] | ThgAdminTemplateViewModelGen[] = [];

  get rendered(): MrfiktivRenderedTemplateViewModelGen | ThgRenderedTemplateViewModelGen | undefined {
    return this._rendered;
  }
  get adminTemplate(): MrfiktivAdminTemplateViewModelGen | ThgAdminTemplateViewModelGen | undefined {
    return this._adminTemplate;
  }
  get adminTemplates(): MrfiktivAdminTemplateViewModelGen[] | ThgAdminTemplateViewModelGen[] {
    return this._adminTemplates;
  }

  @Mutation
  private _mutateRendered(rendered: MrfiktivRenderedTemplateViewModelGen | ThgRenderedTemplateViewModelGen): void {
    this._rendered = rendered;
  }
  @Mutation
  private _mutateAdminTemplate(_adminTemplate: MrfiktivAdminTemplateViewModelGen | ThgAdminTemplateViewModelGen): void {
    this._adminTemplate = _adminTemplate;
  }
  @Mutation
  private _mutateAdminTemplates(
    _adminTemplates: MrfiktivAdminTemplateViewModelGen[] | ThgAdminTemplateViewModelGen[]
  ): void {
    this._adminTemplates = _adminTemplates;
  }

  @Action
  async createTemplate(
    data: MrfiktivCreateAdminTemplateDtoGen | ThgCreateAdminTemplateDtoGen
  ): Promise<MrfiktivCreateAdminTemplateDtoGen | ThgCreateAdminTemplateDtoGen> {
    const created = await adminTemplateService.create(data);
    this.context.commit("_mutateAdminTemplate", created);

    if (created) {
      this._data.set(created);
    }

    return data;
  }

  @Action
  async create(
    data: MrfiktivCreateAdminTemplateDtoGen | ThgCreateAdminTemplateDtoGen
  ): Promise<MrfiktivAdminTemplateViewModelGen | ThgAdminTemplateViewModelGen | undefined> {
    const created = await adminTemplateService.create(data);
    this.context.commit("_mutateAdminTemplate", created);

    if (created) {
      this._data.set(created);
    }

    return created;
  }

  @Action
  async deleteTemplate({ key, language }: { key: string; language: LanguageCodeEnum }): Promise<void> {
    const deleted = await adminTemplateService.delete(key, language);

    if (deleted) {
      this._data.delete(deleted);
    }

    this.context.commit("_mutateAdminTemplate", deleted);
  }

  @Action
  async getAllTemplates(): Promise<void> {
    const all = await adminTemplateService.getAll();

    all?.sort((a, b) => (a.timestamp.created > b.timestamp.created ? -1 : 1));

    this._data.clear();
    all?.forEach(e => this._data.set(e));

    this.context.commit("_mutateAdminTemplates", all);
  }

  @Action
  replaceInList(data: ThgAdminTemplateViewModelGen): void {
    const templates = this.adminTemplates;
    const index = templates.findIndex(t => t.key === data.key);
    if (index > -1) {
      templates.splice(index, 1, data);
    }

    if (data) {
      this._data.set(data);
    }

    this.context.commit("_mutatePartnerTemplates", templates);
  }

  @Action
  async getTemplateByKey({ key, language }: { key: string; language: LanguageCodeEnum }): Promise<void> {
    const template = await adminTemplateService.getByKey(key, language);

    if (template) {
      this._data.set(template);
    }

    this.context.commit("_mutateAdminTemplate", template);
  }

  @Action
  async renderTemplate(args: {
    key: string;
    language: LanguageCodeEnum;
    data: MrfiktivRenderTemplateDtoGen | ThgRenderTemplateDtoGen;
  }): Promise<void> {
    const template = await adminTemplateService.render(args.key, args.language, args.data);

    this.context.commit("_mutateAdminTemplate", template);
  }

  @Action
  async updateTemplate(args: {
    key: string;
    language: LanguageCodeEnum;
    data: MrfiktivUpdateAdminTemplateDtoGen | ThgUpdateAdminTemplateDtoGen;
  }): Promise<MrfiktivAdminTemplateViewModelGen | ThgAdminTemplateViewModelGen | undefined> {
    const template = await adminTemplateService.update(args.key, args.language, args.data);
    this.context.commit("_mutateAdminTemplate", template);

    if (template) {
      this._data.set(template);
    }

    return template;
  }

  @Action
  clearAdminTemplate() {
    this.context.commit("_mutateAdminTemplate", undefined);
  }

  @Action
  clearAdminTemplates() {
    this.context.commit("_mutateAdminTemplates", []);
  }
}

export const AdminTemplateModule = getModule(AdminTemplateStore);
