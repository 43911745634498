

























































































































































































































































































































































import { Component, Prop, Watch } from "vue-property-decorator";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { filterJson } from "@/lib/utility/filter";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { getArrayedNestedPath } from "@/lib/objectPath-helper";
import Debug from "@/components/utility/Debug.vue";
import {
  IControlElements as IControlElements2,
  ITableWrapperHeader as ITableWrapperHeader2
} from "@/lib/types/tableWrapper";
import ContextMenu from "./ContextMenu.vue";

/**
 *  FIXME: Refactoring this is a pain
 * @deprecated use @/lib/types/tableWrapper"
 *
 */
export type IControlElements = IControlElements2;

/**
 *  FIXME: Refactoring this is a pain
 * @deprecated use @/lib/types/tableWrapper"
 */
export type ITableWrapperHeader = ITableWrapperHeader2;

@Component({ components: { LatestEntriesCardEmpty, Debug, ContextMenu } })
export default class TableWrapper extends DarkModeHighlightMixin {
  @Prop({ default: false })
  hideSearch?: boolean;

  @Prop()
  title!: string;

  @Prop({ default: false })
  dense?: boolean;

  @Prop()
  subtitle?: string;

  @Prop()
  loadAll?: Function;

  @Prop()
  confirmNewItems!: Function;

  @Prop({ default: true })
  isConfirmEnabled!: boolean;

  @Prop({ default: true })
  isDropEnabled!: boolean;

  @Prop()
  headers!: ITableWrapperHeader2[];

  @Prop()
  emptyItem!: Record<string, any>;

  @Prop()
  allItems!: Record<string, any>[];

  @Prop()
  sortBy?: string;

  @Prop({ default: true })
  sortDesc?: boolean;

  @Prop({ default: false })
  hideTableHeader?: boolean;

  @Prop({ default: false })
  hideTableFooter?: boolean;

  @Prop({ default: false })
  disablePagination?: boolean;

  @Prop({ default: false })
  singleSelect?: boolean;

  @Prop({ default: false })
  showSelect?: boolean;

  @Prop({ default: () => [] })
  selectedItems!: Record<string, any>[];

  @Prop()
  controlElements?: IControlElements2[];

  @Prop({ default: false })
  loading?: boolean;

  @Prop({ default: true })
  margin?: boolean;

  @Prop({ default: 15 })
  itemsPerPage?: number;

  @Prop({ default: "id" })
  itemKey!: string;

  @Prop({ default: false })
  isClickableRow!: boolean;

  @Prop({})
  groupBy?: string;

  @Prop({ default: false })
  groupDesc?: boolean;

  @Prop()
  page!: number;

  @Prop({ default: true })
  outlined?: boolean;

  @Prop({})
  customGroupMethod?: (items: any[], groupBys: string[], groupDesc: boolean[]) => { name: string; items: any[] }[];

  search = "";

  loadingCreation = false;
  loadingAll = false;

  newItems: Record<string, any>[] = [];

  get hasClickRowListener() {
    return Boolean(this.$listeners && this.$listeners["click:row"]);
  }

  get pageLocal() {
    return this.page;
  }
  set pageLocal(value: number) {
    this.$emit("update:page", value);
  }

  /**
   * Can be used in conjunction with the slot for `selectActions`.
   * A slot can be used like this btn for deletion:
   * ```
   *  <template #selectActions="{selected}">
   *    <v-btn @click="deleteAll(selected)"></v-btn>
   *  </template>
   * ```
   *  The selected items are passed to the slot via `selected` and can be used (as an array) in the function.
   * In above example  all selected items are passed to the deleteAll function.
   */

  get selected() {
    return this.selectedItems;
  }

  set selected(selectedItems: Record<string, any>[]) {
    this.$emit("update:selectedItems", selectedItems);
  }

  get isSelected() {
    return this.selected.length > 0;
  }

  get items() {
    return [...this.newItems, ...this.allItems];
  }

  get i18n() {
    return this.$t("components.utility.TableWrapper");
  }

  simpleDoubleDigitDate(date: string) {
    return simpleDoubleDigitDate(date);
  }

  @Watch("search")
  emitSearch() {
    this.$emit("updateSearch", this.search);
  }

  customFilter(value: any, search: string, item: any) {
    return filterJson(value, search, item);
  }

  getItemValue(item: any, headerValue: string) {
    const found = getArrayedNestedPath(item, headerValue);
    if (found.length === 0) return undefined;
    if (found.length === 1) return found[0];
    return found;
  }

  setItemValue(item: any, headerValue: string, value: string) {
    let schema = item;
    const pList = headerValue.split(".");
    const len = pList.length;
    for (let i = 0; i < len - 1; i++) {
      const elem = pList[i];
      if (!schema[elem]) schema[elem] = {};
      schema = schema[elem];
    }

    schema[pList[len - 1]] = value;
  }

  addItem() {
    if (this.emptyItem) {
      this.newItems.push({ ...this.emptyItem, temporaryWorkId: Math.ceil(Math.random() * 1000000) });
    }
    this.$emit("addItem");
  }

  isNewItem(item: Record<string, any>) {
    return Boolean(item.temporaryWorkId);
  }

  async mounted() {
    this.$log.debug(this.itemKey);
    await this.loadAllWrapper();
  }

  async confirmNewItemsWrapper() {
    try {
      this.loadingCreation = true;
      await this.confirmNewItems(
        this.newItems.map(nI => {
          return { ...nI, temporaryWorkId: undefined };
        })
      );
      this.newItems = [];
    } catch (e) {
      this.$toast.error((e as any).message);
      this.$log.error(e);
    } finally {
      this.loadingCreation = false;
      await this.loadAllWrapper();
    }
  }

  async loadAllWrapper() {
    try {
      this.loadingAll = true;
      if (this.loadAll) {
        await this.loadAll();
      }
    } catch (e) {
      this.$toast.error((e as any).message);
      this.$log.error(e);
    } finally {
      this.loadingAll = false;
    }
  }

  async removeItem(item: Record<string, any>) {
    const newItemIndex = this.newItems.findIndex(nI => nI.temporaryWorkId === item.temporaryWorkId);
    this.newItems.splice(newItemIndex, 1);
    this.$emit("removeItem", item);
  }

  customGroup(items: any[], groupBys: string[], groupDesc: boolean[]): { name: string; items: any[] }[] {
    if (this.customGroupMethod) return this.customGroupMethod(items, groupBys, groupDesc);

    const groupMap: Record<string, any[]> = {};

    for (const item of items) {
      for (const groupBy of groupBys) {
        let groups = getArrayedNestedPath(item, groupBy);

        if (!groups.length) {
          groups = [""];
        }

        for (const group of groups) {
          if (!groupMap[group.toString()]) {
            groupMap[group.toString()] = [];
          }

          groupMap[group.toString()].push(item);
        }
      }
    }

    const keys = Object.keys(groupMap).sort((a, b) => (!groupDesc[0] ? a.localeCompare(b) : b.localeCompare(a)));

    return keys.map(key => {
      return {
        name: key,
        items: groupMap[key]
      };
    });
  }
}
